<template>
  <div id="notification" class="scroll-notification">
    <AppContainer :use-background="true">
      <CardHeader ref="header" :node="headerTitle" />
      <div id="content-scroll" class="content-scroll">
        <div class="content" id="notification-content">
          <template v-if="isLoading">
            <AppLoading />
          </template>
          <template v-else>
            <template v-if="listNotification.length > 0">
              <div
                class="d-flex justify-content-center flex-wrap"
                v-for="(item, index) in listNotification"
                :key="index"
              >
                <transition name="slide-down">
                  <div
                    v-if="item.is_read == 0"
                    class="detail-notification content__noti-inactive"
                  >
                    <div
                      class="d-flex justify-content-between position-relative"
                    >
                      <div
                        class="d-flex content__noti--width"
                        @click="detailNotification(index)"
                      >
                        <div class="content__noti__avatar">
                          <div class="position-relative">
                            <img
                              class="avatar"
                              src="@/assets/image/Group4409.svg"
                            />
                          </div>
                        </div>
                        <div
                          class="
                            d-flex
                            flex-column
                            justify-content-center
                            content__noti--description
                          "
                        >
                          <h3 class="f-w6 name text-left">PreGo エスコート</h3>
                          <span
                            :id="'description' + index"
                            class="f-w3 description text-left"
                            v-if="isShowDetailNotification == index"
                          >
                            {{ item.title }}
                          </span>
                          <span
                            class="f-w3 description text-left"
                            :id="'description' + index"
                            v-else
                          >
                            {{ item.title }}
                          </span>
                        </div>
                      </div>
                      <div class="content__noti__time f-w3">
                        {{ compareTime(item.created_at) }}
                      </div>
                      <hr />
                    </div>
                    <div
                      class="content__noti__body f-w3 text-left"
                      v-if="isShowDetailNotification == index"
                    >
                      <p>{{ item.body }}</p>
                    </div>
                    <hr v-if="isShowDetailNotification == index" />
                    <div
                      v-if="isShowDetailNotification == index"
                      class="d-flex justify-content-center align-items-center"
                      @click="isShowDetailNotification = null"
                    >
                      <img
                        src="@/assets/image/icon_profile/icon-detail-notification.svg"
                        class="icon-drop-down"
                        alt=""
                      />
                    </div>
                  </div>
                  <div v-else class="detail-notification content__noti">
                    <div
                      class="d-flex justify-content-between position-relative"
                      @click="detailNotification(index)"
                    >
                      <div class="d-flex content__noti--width">
                        <div class="content__noti__avatar">
                          <div class="position-relative">
                            <img
                              class="avatar"
                              src="@/assets/image/Group4409.svg"
                            />
                          </div>
                        </div>
                        <div
                          class="
                            d-flex
                            flex-column
                            justify-content-center
                            content__noti--description
                          "
                        >
                          <h3 class="f-w6 name text-left">PreGo エスコート</h3>
                          <span
                            :id="'description' + index"
                            class="f-w3 description text-left"
                            v-if="isShowDetailNotification == index"
                          >
                            {{ item.title }}
                          </span>
                          <span
                            :id="'description' + index"
                            class="f-w3 description text-left"
                            v-else
                          >
                            {{ item.title }}
                          </span>
                        </div>
                      </div>
                      <div class="content__noti__time f-w3">
                        {{ compareTime(item.created_at) }}
                      </div>
                      <hr />
                    </div>
                    <div
                      class="content__noti__body f-w3 text-left"
                      v-if="isShowDetailNotification == index"
                    >
                      <p>{{ item.body }}</p>
                    </div>
                    <hr v-if="isShowDetailNotification == index" />
                    <div
                      v-if="isShowDetailNotification == index"
                      class="d-flex justify-content-center align-items-center"
                      @click="detailNotification(index)"
                    >
                      <img
                        src="@/assets/image/icon_profile/icon-detail-notification.svg"
                        class="icon-drop-down"
                        alt=""
                      />
                    </div>
                  </div>
                </transition>
              </div>
              <scroll-loader
                :loader-method="getNotificationList"
                :loader-disable="disable"
              >
              </scroll-loader>
            </template>
            <template v-else>
              <p>現在お知らせはありません。</p>
            </template>
          </template>
        </div>
      </div>
      <CardFooter ref="footer" />
    </AppContainer>
  </div>
</template>
<script>
import AppContainer from "@/views/Common/AppContainer";
import CardHeader from "@/components/CardHeader";
import { timeSimple } from "@/utils/timeFormat";
import { mapGetters } from "vuex";

export default {
  name: "Notification",
  components: {
    AppContainer,
    CardHeader
  },
  metaInfo() {
    return {
      title: "お知らせ",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      headerTitle: {
        text: "お知らせ",
        isShowTitle: true,
        isShowLogo: false
      },
      isShowDetailNotification: null,
      notificationSelected: 1,
      notificationFilter: [
        {
          id: 1,
          value: "通知"
        },
        {
          id: 2,
          value: "運営からのお知らせ"
        }
      ],
      listNotification: [],
      disable: false,
      page: 1,
      perPage: 10,
      params: {}
    };
  },
  async created() {
    this.$store.dispatch("common/setIsLoading", true);
    this.params = { page: this.page, perpage: this.perPage };
    await this.getData(this.params);
    this.$store.dispatch("common/setIsLoading", false);
  },
  mounted: function() {
    let touchStart, touchEnd;
    let self = this;
    let elements = document.getElementById("notification");
    elements.addEventListener("touchstart", swipe);
    elements.addEventListener("touchend", swipe);
    elements.addEventListener("touchmove", swipe);

    function swipe(event) {
      if (event.type == "touchstart") {
        touchStart = event.targetTouches[0].clientY;
      }
      if (event.type == "touchmove") {
        touchEnd = event.targetTouches[0].clientY;
      }
      if (
        document.getElementById("notification-content").getBoundingClientRect()
          .top >= 42
      )
        if (event.type == "touchend") {
          if (touchEnd - touchStart >= 150) {
            self.$root.$refs.loading.start();
            window.location.reload();
          }
        }
      if (event.type == "touchend") {
        touchEnd = 0;
      }
    }
  },
  computed: {
    ...mapGetters({
      NorificationList: "notificationSetting/List",
      isLoading: "common/isLoading"
    })
  },
  methods: {
    changeFilter(index) {
      this.notificationSelected = index;
    },
    detailNotification(index) {
      this.listNotification[index].is_read = 1;
      if (this.isShowDetailNotification == index) {
        this.isShowDetailNotification = null;
        document.getElementById("description" + index).style.whiteSpace =
          "nowrap";
      } else {
        if (this.isShowDetailNotification != null) {
          document.getElementById(
            "description" + this.isShowDetailNotification
          ).style.whiteSpace = "nowrap";
        }
        this.isShowDetailNotification = index;
        document.getElementById("description" + index).style.whiteSpace =
          "normal";
      }
    },
    async getData(params) {
      await this.$store
        .dispatch("notificationSetting/getNotificationList", params)
        .then(response => {
          let total = response.data.total;
          this.listNotification = [
            ...this.listNotification,
            ...response.data.box_notifications
          ];
          if (total <= this.perpage || total < this.page * this.perPage) {
            this.disable = true;
          } else {
            this.disable = false;
          }
          this.page++;
          if (this.page > 1) {
            this.params = params;
            this.params.page = this.page;
          }
        });
    },
    async getNotificationList() {
      if (this.page === 1) return false;
      await this.getData(this.params);
      this.$store.dispatch("common/setIsLoading", false);
    },
    compareTime(time) {
      return timeSimple(time);
    }
  }
};
</script>
<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

@import "~@/assets/scss/notification.scss";
@import "~@/assets/scss/_fontFamily.scss";

.content__noti__body {
  margin: 0;
  padding: 10px 10px;
  margin-bottom: 0;
  color: #000;
  font-size: 14px;
  word-break: break-word;
  white-space: break-spaces;
}

@media screen and (min-width: 1200px) {
  .content__noti__body {
    font-size: 16px;
  }
}
</style>

<style lang="scss" scoped>
.content-scroll {
  // overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 42px;
  padding-bottom: 150px;
}
@media screen and (min-width: 1200px) {
  .content-scroll {
    height: calc(100vh);
    // height: calc(100vh - 152px);
  }
}
</style>
