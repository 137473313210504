<template>
  <div
    :class="[
      { default: useBackground },
      { shadow: useShadow },
      { _header: hasHeader },
      { fixheight: fixHeight }
    ]"
    id="layout-default"
    class="app-body"
  >
    <div class="app-container">
      <div
        class="app-container__content"
        :class="!useBackground ? 'app-container__bg' : ''"
        id="app-container__content"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppContainer",
  props: {
    useBackground: {
      type: Boolean,
      default: false
    },
    useShadow: {
      type: Boolean,
      default: true
    },
    hasHeader: {
      type: Boolean,
      default: true
    },
    fixHeight: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.app {
  &-body {
    overflow-x: hidden;
    &.default {
      background-image: url("../../assets/image/background/order_list_bg.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    &.shadow {
      box-shadow: none !important;
    }
    &._header {
      .app-container {
        &__content {
          // padding: 42px 0 65px;
        }
      }
    }

    &.fixheight {
      .app-container {
        &__content {
          // height: 100vh;
        }
      }
    }
  }

  &-container {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    position: relative;
    // height: 100vh;
    &__content {
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      z-index: 2;
      background: #ffffff;
    }
    &__bg {
      background: none;
    }
  }
}

@media screen and (min-width: 992px) {
  .app {
    &-body {
      &.fixheight {
        .app-container {
          &__content {
            height: 100vh;
          }
        }
      }
    }

    &-container {
      height: 100vh;
      &__content {
        min-height: 100vh;
      }
    }
  }

  .app-body {
    &._header {
      .app-container {
        &__content {
          // padding: 61px 0 91px;
        }
      }
    }
    &.shadow {
      .app-container {
        &::after {
          content: "";
          position: absolute;
          width: calc(100% + 200px);
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-image: url("../../assets/image/bg.svg");
          background-position: center;
          background-size: initial;
        }
      }
    }
  }
}
</style>
